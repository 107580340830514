import React, { useRef, useState, useEffect } from 'react';
import './App.css';

class VideoModel {
  constructor(name, base_url, twitter_url, higher_res=false) {
    this.name = name.toLowerCase();
    this.twitter_url = twitter_url;

    const video_url_base = base_url + '-compressed'

    const video_url_med = video_url_base + '.mov'
    const video_url_low = video_url_base + 'VL.mov'

    this.video_url = higher_res ? video_url_med : video_url_low

    this.thumbnail = base_url + '.jpg'
  }
}

class RowModel {
  constructor(videos = []) {
    this.videos = videos;
  }
}

const rows = [
  new RowModel([
    new VideoModel('3d wallpaper', 'https://janumtrivediportfolio.s3.amazonaws.com/23-12-19+Terrain', 'https://twitter.com/jmtrivedi/status/1736890289800069530'),
    new VideoModel('magnification loupe', 'https://janumtrivediportfolio.s3.amazonaws.com/23-06-15+Magnification+Loupe', 'https://twitter.com/jmtrivedi/status/1669469698348699652'),
    new VideoModel('variable blur', 'https://janumtrivediportfolio.s3.amazonaws.com/23-05-29+Variable+Blur', 'https://twitter.com/jmtrivedi/status/1663235425279041536'),
  ]),
  new RowModel([
    new VideoModel('netflix (shipped january, 2023)', 'https://janumtrivediportfolio.s3.amazonaws.com/23-01-16+Netflix+UI', 'https://twitter.com/jmtrivedi/status/1615095149687894016', true),
  ]),
  new RowModel([
    new VideoModel('dynamic dock', 'https://janumtrivediportfolio.s3.amazonaws.com/23-05-02+Dynamic+Dock', 'https://twitter.com/jmtrivedi/status/1653443691317706756', true),
  ]),
  new RowModel([
    new VideoModel('fabric grid', 'https://janumtrivediportfolio.s3.amazonaws.com/23-04-25+Fabric+Grid', 'https://twitter.com/jmtrivedi/status/1650903244598812673'),
    new VideoModel('space', 'https://janumtrivediportfolio.s3.amazonaws.com/23-01-13+Space', 'https://twitter.com/jmtrivedi/status/1614013689224650752'),
    new VideoModel('z blending', 'https://janumtrivediportfolio.s3.amazonaws.com/22-10-3+Z+Blending', 'https://twitter.com/jmtrivedi/status/1576986617268490240'),
  ]),
  new RowModel([
    new VideoModel('pinch to pip', 'https://janumtrivediportfolio.s3.amazonaws.com/23-01-09+Pinch+to+Zoom', 'https://twitter.com/jmtrivedi/status/1612541509861269505'),
    new VideoModel('chonky menu', 'https://janumtrivediportfolio.s3.amazonaws.com/23-01-02+Chonky+Menu', 'https://twitter.com/jmtrivedi/status/1610017363218563072'),
  ]),
  new RowModel([
    new VideoModel('layout morphing', 'https://janumtrivediportfolio.s3.amazonaws.com/22-05-06+File+Layout+Switcher', 'https://twitter.com/jmtrivedi/status/1522605342894428160'),
    new VideoModel('metaballs', 'https://janumtrivediportfolio.s3.amazonaws.com/22-09-30+Metaball', 'https://twitter.com/jmtrivedi/status/1575905165789392896'),
  ]),
  new RowModel([
    new VideoModel('card dodging', 'https://janumtrivediportfolio.s3.amazonaws.com/22-06-24+Card+Dodging', 'https://twitter.com/jmtrivedi/status/1541458610143625218'),
  ]),
  new RowModel([
    new VideoModel('fast focus switching', 'https://janumtrivediportfolio.s3.amazonaws.com/22-05-23+Fast+Focus+Switching', 'https://twitter.com/jmtrivedi/status/1528759111839408129'),
    new VideoModel('vfx', 'https://janumtrivediportfolio.s3.amazonaws.com/22-05-14+VFX', 'https://twitter.com/jmtrivedi/status/1525590613852188672'),
  ]),
  new RowModel([
    new VideoModel('fluid drag & drop', 'https://janumtrivediportfolio.s3.amazonaws.com/22-05-02+Fluid+Drag+and+Drop', 'https://twitter.com/jmtrivedi/status/1521190109617410048'),
    new VideoModel('waveform scrubber', 'https://janumtrivediportfolio.s3.amazonaws.com/22-10-05+Waveform', 'https://twitter.com/jmtrivedi/status/1577711102099566592'),
  ]),
  new RowModel([
    new VideoModel('fluid toolbar', 'https://janumtrivediportfolio.s3.amazonaws.com/22-04-22+Fluid+Toolbar', 'https://twitter.com/jmtrivedi/status/1517561485622321152'),
    new VideoModel('specular lighting', 'https://janumtrivediportfolio.s3.amazonaws.com/22-04-03+Specular+Light', 'https://twitter.com/jmtrivedi/status/1510728668670140417'),
    new VideoModel('stack preview', 'https://janumtrivediportfolio.s3.amazonaws.com/22-04-01+Stack+Preview', 'https://twitter.com/jmtrivedi/status/1510014145126612993'),
  ]),
];

function VideoComponent({ video }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !shouldLoad) {
        setShouldLoad(true);
      }
    });
  };

  const handleMouseOver = () => {
    setIsHovered(true);
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleMouseOut = () => {
    setIsHovered(false);
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const videoStyle = {
    borderRadius: '10px'
  };


   const videoContainerStyle = {
    opacity: shouldLoad ? 1 : 0,
    transition: 'opacity 0.5s',
    transform: (isHovered && isPlaying) ? 'scale(1.04)' : 'scale(1)', // Add scale on hover
    transitionProperty: 'transform, opacity', // Add transition for scale and opacity
    transitionDuration: '0.3s', // Adjust the duration of the transition
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="column">
      <div
        className={`blue-column ${isHovered ? 'hovered' : ''}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        style={videoContainerStyle}
      >
        <video
          ref={videoRef}
          muted
          controls={false}
          autoPlay={false}
          preload={shouldLoad ? 'auto' : 'none'}
          style={videoStyle}
          width="100%"
          height="100%"
          poster={video.thumbnail}
        >
          <source src={video.video_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <a target="_blank" rel="noopener noreferrer" href={video.twitter_url} className="SmallCaps">
        {video.name} →
      </a>
    </div>
  );
}

function App() {
  return (
    <div className="RootContainer">
      <div className="ContentContainer">

        <div className="Link">
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/jmtrivedi">Twitter</a>
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/jtrivedi">GitHub</a>
        </div>

        <div className="Header">
          I'm Janum Trivedi, an interaction designer and engineer. I design,
          prototype, and build fluid, delightful UIs for iOS.
        </div>

        <div className="Subheader">
          I'm currently at Airbnb. Before that, I was at The Browser Company, Netflix, and Apple.
        </div>

        <div className="Subheader">
          Here are some of the designs and prototypes I've recently made. More on <a href="https://twitter.com/jmtrivedi">Twitter</a>.
        </div>

        {/*<div className="Subheader">
          <a href="https://github.com/jtrivedi">GitHub → </a><a href="https://www.linkedin.com/in/janumtrivedi">LinkedIn →</a>
        </div>*/}

        <div className="ProjectContainer">
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="row">
              {row.videos.map((video, index) => (
                <VideoComponent key={index} video={video} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;